import { useCallback, useContext, useState } from 'react'

import { history } from '../../navigation/history'

import { TemplateContext } from '../template/context'

export const useMenu = ({ isMenuDesktopOpened, setIsMenuDesktopOpened }) => {
  const [isMenuMobileOpened, setIsMenuMobileOpened] = useState(false)
  const { isSubMenuToggled, setIsSubMenuToggled, menuOptions } = useContext(TemplateContext)
  const pathName = history.location.pathname

  const effected = pathName === '/dashboard/declaracoes/enviadas'
  const pending = pathName === '/dashboard/declaracoes/pendentes'

  const handleMenuMobileOpen = isDeclaration => {
    if (isDeclaration) {
      if (pending) return history.push('/dashboard/declaracoes/enviadas')

      return history.push('/dashboard/declaracoes/pendentes')
    }
    setIsMenuMobileOpened(!isMenuMobileOpened)
  }

  const handleIsMenuDesktopOpened = useCallback(() => {
    setIsMenuDesktopOpened(!isMenuDesktopOpened)
  }, [isMenuDesktopOpened])

  const handleSubMenuToggle = useCallback(
    optionId => {
      const findOption = menuOptions?.find(option => option?.id === optionId)
      if (findOption) {
        setIsSubMenuToggled({
          id: findOption.id,
          toggled: isSubMenuToggled.id === findOption.id ? !isSubMenuToggled.toggled : true
        })
      }
    },
    [isSubMenuToggled, menuOptions]
  )

  return {
    isMenuMobileOpened,
    handleMenuMobileOpen,
    isMenuDesktopOpened,
    setIsMenuDesktopOpened,
    handleIsMenuDesktopOpened,
    handleSubMenuToggle,
    pathName,
    effected,
    pending
  }
}
